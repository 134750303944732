<template>
  <div>
    <v-dialog v-model="recalcularDialog" scrollable persistent max-width="500" @input="onDialogOpen">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-icon>mdi-database-refresh-outline</v-icon>
          Recalcular
        </div>
      </template>

      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-cog-sync</v-icon>
          Recalcular
          <v-btn
            @click="recalcularDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p><b>Selecione o período para recalcular:</b></p>
          <v-menu
            v-model="dateMenu"
            :close-on-click="intervalo.length == 2"
            :close-on-content-click="false"
            transition="scroll-y-transition"
            offset-y
            right
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="intervalo | toMonthPeriod"
                dense
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @click:prepend.stop="on.click"
                v-on="on"
                hide-details
                class="mb-n1 mt-0 mr-3"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="intervalo"
              range
              type="month"
              @click:month="dateMenu = intervalo.length != 2"
              no-title
            ></v-date-picker>
          </v-menu>
          <br />
          <p>
            Selecione um ou mais produtos. Deixe em branco para selecionar
            todos.
          </p>
          <v-autocomplete
            v-model="produtosSelecionados"
            :disabled="!produtos || produtos.length === 0"
            :items="produtos"
            placeholder="Todos os produtos"
            dense
            multiple
            hide-details
            clearable
            prepend-icon="mdi-account"
            :search-input.sync="searchInputValue"
            @blur="searchInputValue = ''"
          ></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="recalcularDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="recalcularPeriodo()"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['clientId']),
  },
  created: function () {
    this.apiResource(`/v1/faturamento/produtos/${this.clientId}`)
      .get()
      .then((response) => {
        this.produtos = response.map(({ abreviacao, id, incentivo }) => ({
          text: `${incentivo} — ${abreviacao}`,
          value: id,
        }));
      });
  },
  data: function () {
    return {
      intervalo: [],
      produtos: [],
      produtosSelecionados: [],
      recalcularDialog: false,
      searchInputValue: '',
    };
  },
  methods: {
    onDialogOpen: function (value) {
      if (value) {
        this.intervalo = this.$store.getters.intervaloCompetencia;
      }
    },
    recalcularPeriodo: function () {
      const [ini, fim] = this.intervalo;
      const produtos = Array.isArray(this.produtosSelecionados)
        ? this.produtosSelecionados
        : [];
      let query = `competenciaIni=${ini}&competenciaFim=${fim}`;

      if (produtos.length > 0) {
        query += `&produtos=[${produtos}]`;
      }

      return this.apiResource(
        `/v1/faturamento/calculo/${this.clientId}?${query}`
      )
        .save()
        .then((response) => {
          if (!response.error) {
            this.recalcularDialog = false;
          }
        });
    },
  },
  name: 'recalculate-products',
};
</script>
